import React, { useState, useRef, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";

import moment from 'moment';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { 
		Button, 
		Modal, 
		Row,
		Col,
		Form,
		} from "react-bootstrap";

import Sidebar from "../components/Sidebar/Sidebar";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import routes from "../routes";
import { listsData } from '../data';


export default function Admin() {
	
const [color, setColor] = useState("gray");
const [tasks, setTasks] = useState(listsData);
const [showAddTaskModal, setShowAddTaskModal] = useState(false);
const [showEditTaskModal, setShowEditTaskModal] = useState(false);
const [date, setDate] = useState(new Date());
const [taskColor, setTaskColor] = useState("#fdf2b3");

	var colorItems = [
				{bgcolor:'#fdf2b3', bgName:'bg1'}, 
				{bgcolor:'#d1eaed', bgName:'bg2'},
				{bgcolor:'#ffdada', bgName:'bg3'},
				{bgcolor:'#ffd4a9', bgName:'bg4'}
				];


	const taskTitle = useRef('');
	const taskSummary = useRef('');
	
	const notify = (msg,msgType) => {
		
	var toastMsg;
		
	switch (msgType) {
      case 'info':
        toastMsg = toast.info;
        break;
      case 'success':
        toastMsg = toast.success;
        break;
      case 'warn':
        toastMsg = toast.warn;
        break;
      case 'error':
        toastMsg = toast.error;
        break;
      default:
	    toastMsg = toast;
        break;
    }	
	
	var options = { 
				position: "top-center",
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: true,
				draggable: true,
				theme: "light",
				}
	
	toastMsg(msg, options);

};
	

	function loadTasks() {		
		let data = JSON.parse(localStorage.getItem('todotasks'));
		if (data !== null && Object.keys(data).length !== 0) {
			setTasks(data);
		}		
	}

	useEffect(() => {
		loadTasks();
	}, []);	

	function deleteTask(index) {
		
		var clonedTasks = [...tasks];
		
		clonedTasks.splice(index, 1);		
		setTasks(clonedTasks);		
		saveTasks([...clonedTasks]);
		notify('Task Deleted Successfully', 'success');
		
	}

	function completedTask(index) {
		
		var clonedTasks = [...tasks];		
		
		{clonedTasks[index].status === 'completed' ? (
		
			clonedTasks[index].status = 'inprogress'			
			
		) : (		
			clonedTasks[index].status = 'completed'	
			
		)}	

	   notify('Completed Status Updated', 'info');	

		setTasks(clonedTasks);
		saveTasks([...clonedTasks]);
	}

	function favouriteTask(index) {
		
		var clonedTasks = [...tasks];		
		
		{clonedTasks[index].favourite === 'yes' ? (		
			clonedTasks[index].favourite = 'no'		
		) : (		
			clonedTasks[index].favourite = 'yes'		
		)}			

	    notify('Favourite Status Updated', 'info');

		setTasks(clonedTasks);
		saveTasks([...clonedTasks]);
	}	
	
 	function saveTasks(tasks) {
		localStorage.setItem('todotasks', JSON.stringify(tasks));
	}
	
	const [editTaskIndex, setEditTaskIndex] = useState('');	
	const [editTaskValue, setEditTaskValue] = useState({title: "", summary: ""});
	
	
	function editTask(index) {
	
		setShowEditTaskModal(true);		
		
		var clonedTasks = [...tasks];
		var editTaskData = clonedTasks[index];		
		
		setEditTaskIndex(index);		
		
		editTaskValue.title = editTaskData.taskTitle;
		editTaskValue.summary = editTaskData.taskSummary;
		
		setDate(dateUnFormat(editTaskData.taskDate));		
		setTaskColor(editTaskData.taskBgColor);				
	
	}	
	
	function updateTask(index) {
				
		let updateTaskTitle =  taskTitle.current.value;
		let updateTaskSummary =  taskSummary.current.value;
		
		if(updateTaskTitle && updateTaskSummary){
		   var clonedTasks = [...tasks];
			
			clonedTasks[index].taskTitle=updateTaskTitle;
			clonedTasks[index].taskSummary=updateTaskSummary;
			clonedTasks[index].taskDate=dateFormat(date);
			clonedTasks[index].taskBgColor=taskColor;
									
			setTasks(clonedTasks);
			saveTasks([...clonedTasks]);
			
			notify('Task Updated Successfully', 'success');			
			
			setShowEditTaskModal(false);
			
		}else{
			notify('Please Enter Title And Summary', 'error');
		}
				
	}

	const dateFormat = (dateString) => {
		return moment(new Date(dateString)).format('MM/DD/YYYY')
	};

	const selectTaskColor = (stc) => {	
		setTaskColor(stc);
	};
	
	const dateUnFormat = (dateString) => {
		var d = new Date(dateString);
		return d;
	};		

    const createTask = () =>{ 
	
	var lastRowId=0;
	var i = 0;
	
		if(tasks.length > 0){
			lastRowId = tasks.length - 1
			i =  (tasks[lastRowId].id + 1)
		}		
		
		let t =  taskTitle.current.value;
		let s =  taskSummary.current.value;				
		let sts = 'inprogress';	

		if(t && s){	
		
		setTasks([
			...tasks,
			{
				id:i,
				status:sts,
				favourite:'no',
				taskDate:dateFormat(date),
				taskTitle: 	taskTitle.current.value,
				taskSummary: taskSummary.current.value,
				taskBgColor: taskColor,
				
			},
		]);	
		
		saveTasks([
			...tasks,
			{
				id:i,
				status:sts,
				favourite:'no',
				taskDate:dateFormat(date),
				taskTitle:taskTitle.current.value,
				taskSummary:taskSummary.current.value,
				taskBgColor:taskColor,
			},
		]);
		
		notify('Your task successfully added', 'success');
		setShowAddTaskModal(false);
		
		} else {

			notify('Please Enter Title And Summary', 'error');			
		}
		
    }


 const getRoutes = (routes, tasks, setTasks, deleteTask,completedTask, favouriteTask, editTask, setShowAddTaskModal, setDate) => {
	 
    return routes.map((prop, key) => {
      if (prop.layout === "/stickynotes") {	  
		  
        return (
          <Route  path={prop.layout + prop.path} element={<prop.component tasks={tasks} setTasks={setTasks} deleteTask={deleteTask} completedTask={completedTask} favouriteTask={favouriteTask} editTask={editTask} setShowAddTaskModal={setShowAddTaskModal} setDate={setDate} />}  key={key} />	
        );
      } else {
        return null;
      }
    });
  };
	
  return (
    <div className="wrapper">
	  <Sidebar color={color} routes={routes}/>
	  
	   <div className="main-panel" >
				
		<ToastContainer />
		
		  <AdminNavbar tasks={tasks} setTasks={setTasks} createTask={createTask} taskTitle={taskTitle} taskSummary={taskSummary} setShowAddTaskModal={setShowAddTaskModal} setDate={setDate} />

          <div className="content">				
		    <Routes>{getRoutes(routes, tasks, setTasks, deleteTask,completedTask, favouriteTask, editTask, setShowAddTaskModal, setDate)}</Routes> 
		  </div>


     {/* Add Modal */}
        <Modal
          className="modal modal-primary ci-model"
          show={showAddTaskModal}
          onHide={() => setShowAddTaskModal(false)}			  
        >		
        <Modal.Header closeButton>New Nots</Modal.Header>		  
          <Modal.Body>		  
		   <Form>

			  <Row>				  
				<Col md="12">				 
					<div className="colors">					
					  <Form.Group controlId="addTaskColor">
					
						{colorItems.map((colorItem, index) => {
							return (							
								<input key = {index} type='radio' name="color" 						
								onClick={() => {selectTaskColor(colorItem.bgcolor);}}						
								className={colorItem.bgName}
								defaultChecked={colorItem.bgcolor === taskColor}/>	
								)						
						})}		
					 </Form.Group>					
					</div>					 
				</Col>				
			  </Row>
		   
			  <Row>				  
				<Col md="12">
				  <Form.Group>
					<label>Title</label>
					<Form.Control                         
					  placeholder="Title"
					  type="text"
					  name="taskTitle"
					  ref={taskTitle}
					 
					  
					>
				  </Form.Control>
				 </Form.Group>							  
				</Col>				
			  </Row>                
			  <Row>
				<Col md="12">
				  <Form.Group>
					<label>Summary</label>
					<Form.Control					
					  className="tasksummary"
					  name="taskSummary"
					  ref={taskSummary}
					  placeholder="Task Summary"
					  rows="8"
					  as="textarea"
					></Form.Control>
				  </Form.Group>
				</Col>
			  </Row>
			                  
			  
              <br></br>				  	
			  <Button
              className="btn-simple"
              type="button"
              variant="link"
              onClick={() => setShowAddTaskModal(false)}>Cancel</Button>
				  
		      <Button className="btn-fill ml-2 float-right"
               type="button"
               variant="primary"
			   onClick={() => {createTask();}}
			    >Submit</Button>				  
               <div className="clearfix"></div>				  
			</Form>				 
          </Modal.Body>		  
        </Modal>
        {/* End Add Modal */}	

        {/* Edit Modal */}
        <Modal
          className="modal modal-primary ci-model"
          show={showEditTaskModal}
          onHide={() => setShowEditTaskModal(false)}			  
        >		
        <Modal.Header closeButton>Edit Task</Modal.Header>		  
          <Modal.Body>		  
		   <Form>		   
		   
			  <Row>				  
				<Col md="12">					 
					<div className="colors">					
					  <Form.Group controlId="editTaskColor">					
						{colorItems.map((colorItem, index) => {							
							return (							
								<input key = {index} type='radio' name="color" 						
								onClick={() => {selectTaskColor(colorItem.bgcolor);}}						
								className={colorItem.bgName}
								defaultChecked={colorItem.bgcolor === taskColor}/>	
								)						
						 })}		
					  </Form.Group>					
					</div>					 
				</Col>				
			  </Row>		   
		   
			  <Row>				  
				<Col md="12">
				  <Form.Group>
					<label>Title</label>
					<Form.Control                         
				     placeholder="Title"
				     type="text"
				     name="taskTitle"
				     ref={taskTitle}
				     value= {editTaskValue.title}
					 onChange={(event) => setEditTaskValue({ ...tasks, title: event.target.value })}
					 
					>
				  </Form.Control>
				 </Form.Group>							  
				</Col>				
			  </Row>                
			  <Row>
				<Col md="12">
				  <Form.Group>
					<label>Summary</label>
					
					<Form.Control					
					  className="tasksummary"
					  name="taskSummary"
					  ref={taskSummary}
					  placeholder="Task Summary"
					  rows="8"
					  as="textarea"
					  defaultValue={editTaskValue.summary}
					  
					></Form.Control>
				  </Form.Group>
				</Col>
			  </Row>
			  
              <br></br>
				  	
			  <Button
              className="btn-simple"
              type="button"
              variant="link"
              onClick={() => setShowEditTaskModal(false)}>Cancel</Button>
				  
		      <Button className="btn-fill ml-2 float-right"
               type="button"
               variant="primary"
			   onClick={() => {updateTask(editTaskIndex);}}
			    >Update</Button>				  
               <div className="clearfix"></div>				  
			</Form>				 
          </Modal.Body>		  
        </Modal>
        {/* Edit Modal */}		
         
        </div>		
    </div>	
  )
}
