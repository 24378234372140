import React from "react";
import PropTypes from 'prop-types';
import { NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";

function Sidebar({ color, routes }) {
	
	
	const mobileMenuClick = (e) => {	   
		if(window.innerWidth < 991){		   
			e.preventDefault();
			document.documentElement.classList.toggle("nav-open");
			var node = document.createElement("div");
			node.id = "bodyClick";
			node.onclick = function () {
			  this.parentElement.removeChild(this);
			  document.documentElement.classList.toggle("nav-open");
			};
			document.body.appendChild(node);		  
	   }	   
   }

  return (
    <div className="sidebar" data-color={color} onClick={mobileMenuClick}>
	
      <div className="sidebar-background"  />
	  
		<div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start sidebar-header">
          <a
            href="#"
            className="simple-text logo-mini mx-1"
          >
            <div className="logo-img">
              <img src={require("../../assets/img/logo.png")} alt="..." />
            </div>
          </a>
          <a className="logo-text" href="#">Sticky Notes</a>
        </div>
		
      <Nav>
          {routes.map((prop, key) => {			 
			  
            if (!prop.redirect)
              return (
                <li key={key}>                  
				   <NavLink
                      to={prop.layout + prop.path}
                      className="nav-link"                      
                    >
					 <prop.icon   size={20} />                      
                     <p>{prop.name}</p>
                    </NavLink>                 
				  
                </li>
              );
			  
            return null;
          })}		
		  
        </Nav>
		
      </div>
    </div>
  );
}

Sidebar.propTypes = {
    name: PropTypes.string,
  };

export default Sidebar;
