import React from 'react';
import Masonry from 'react-masonry-css';

import {
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function InProgress({tasks, setTasks, deleteTask, completedTask, favouriteTask, editTask}) {

	const breakpointColumnsObj = { 
	  default: 4,
	  1280: 3,
	  700: 2,
	  500: 1
	};

  return (
	<>
      <Container fluid>	 
	   <Row>
	   {tasks.length > 0 ? (
								
		<Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid"
			columnClassName="my-masonry-grid_column" >
  
		 {tasks.map((task, index) => {			
			
			return (
			   
				<div className="task" key = {index} style={{backgroundColor: task.taskBgColor }}>	

				  <div className="taskbody">				

					{task.taskTitle.length > 25 ? (					
						<div className="title"><h5>{task.taskTitle.substring(0,24)+'...'}</h5></div>
					  ) : (					
					    <div className="title"><h5>{task.taskTitle}</h5></div>
					)}				
					
					{task.taskSummary.length > 260 ? (
						<div className="summary scroll"><p>{task.taskSummary}</p></div> 
					  ) : (
						<div className="summary noscroll"><p>{task.taskSummary}</p></div> 
					)}					
				  </div>

			      <div className="taskmenu" id={index}>					  
					<div className="taskmenu-items">					  
					  <div className="stats edit">
						<i className="fa-solid fa-pen-to-square"					
						onClick={(event) => {editTask(index)}}								
						></i>
						
					  </div>					  
					  <div className="stats delete">
						<i className="fa-solid fa-trash-can" onClick={() => {deleteTask(index);}} ></i>
					  </div>						
						
					  {task.status === 'completed' ? (
					  
					  <div className="stats completed">
						<i className="fa-solid fa-circle-check" onClick={() => {completedTask(index);}}></i>
					  </div>
					
					  ) : (	
					  
					  <div className="stats">
						<i className="fa-regular fa-circle-check mr-5" onClick={() => {completedTask(index);}} ></i>
					  </div>
					  
					  )}						

					  {task.favourite === 'yes' ? ( 
					  
					  <div className="stats favourite">
						<i className="fa-solid fa-heart" onClick={() => {favouriteTask(index);}}></i>
					  </div>
					  
					  ) : (
					  
					  <div className="stats">
						<i className="fa-solid fa-heart" onClick={() => {favouriteTask(index);}}></i>
					  </div>
					  
					  )}	  
					  
				  </div>	
				</div> 
			  </div>			
			);				
		})}  
	   </Masonry>							
								
		) : (
															
		  <Col>
			<Card className="card-stats task">			
			  <Card.Body>
				<p>you do not have any In Progress note , Please add Note</p>
			  </Card.Body>	
			</Card>			 
		 </Col>
	   )}
	   
	  </Row>	  
     </Container>
	</>
  );	
	

}

export default InProgress;
