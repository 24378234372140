import React from "react";
import { useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import { 
		Navbar, 
		Container, 
		Nav,  
		Button, 
		} from "react-bootstrap";

import routes from "../../routes.js";


function Header({tasks, setTasks, createTask, taskTitle, taskSummary, setShowAddTaskModal,setDate}) {
	
  const location = useLocation();
  
  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };
  


  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  
  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">          
		  
		<div className="d-lg-none mobilemenu" onClick={mobileSidebarToggle}>
			<i className="nc-icon nc-align-center icon-bold"></i>
		</div>
		  
          <Navbar.Brand
            href="#home"
            onClick={(e) => e.preventDefault()}
            className="mr-2"
          >
            {getBrandText()}
          </Navbar.Brand>
        </div>
	
		
       	
          <Nav className="nav mr-auto" navbar></Nav>		  
            <Nav className="ml-auto" navbar>
			  <Button className="btn-fill mr-1"
                    type="button"
                    variant="primary"
					onClick={() => {setShowAddTaskModal(true); setDate(new Date())}}					
                  >Add New
              </Button>    
           </Nav>		  
      
		
      </Container>
    </Navbar>
  );
}


Header.propTypes = {
    name: PropTypes.string,
  };

export default Header;
