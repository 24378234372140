import React from "react";
import './App.css';

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/stickynotes.css";
import "./assets/css/style.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import * as Icon from 'react-bootstrap-icons';




import AdminLayout from './layouts/Admin';

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

function App() {

  return (
        <>
          <Router>
             <Routes>				
				<Route path="/*" element={<AdminLayout replace to="/stickynotes/allnots" />} />				
				<Route path="/" element={<Navigate replace to="/stickynotes" />} />	
				<Route path="/stickynotes" element={<Navigate replace to="/stickynotes/allnots" />} />				
            </Routes>
          </Router>     
        </>
  );
}

export default App;
