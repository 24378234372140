import AllNots from "./views/AllNots";
import Completed from "./views/Completed";
import InProgress from "./views/InProgress";
import Favourite from "./views/Favourite";
import * as Icon from 'react-bootstrap-icons';

const dashboardRoutes = [

  {
    path: "/allnots",
    name: "All Nots",
    icon: Icon.JournalText,
    component: AllNots,
    layout: "/stickynotes"
  },
 
  {
    path: "/favourite",
    name: "Favourite",
    icon: Icon.Heart,
    component: Favourite,
    layout: "/stickynotes"
  }, 
  
  {
    path: "/inprogress",
    name: "In Progress",
    icon: Icon.ArrowRepeat,
    component: InProgress,
    layout: "/stickynotes"
  },  

  {
    path: "/completed",
    name: "Completed",
    icon: Icon.CheckCircle,
    component: Completed,
    layout: "/stickynotes"
  },


];

export default dashboardRoutes;
