import React from 'react';
import Masonry from 'react-masonry-css';
import * as Icon from 'react-bootstrap-icons';

import {
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";


function Favourite({tasks, setTasks, deleteTask, completedTask, favouriteTask, editTask}) {
	
	const breakpointColumnsObj = { 
	  default: 4,
	  1280: 3,
	  700: 2,
	  500: 1
	};	

  return (
	<>
      <Container fluid>	 
	   <Row>
	   {tasks.filter(task => task.favourite === "yes").length > 0 ? (
								
		<Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid"
			columnClassName="my-masonry-grid_column" >
  
		 {tasks.map((task, index) => {	
		 
			if(task.favourite === "yes"){
				
			return (
			   
				<div className="task" id={'task'+index} key = {index} style={{backgroundColor: task.taskBgColor }}>	

				  <div className="taskbody">				

					{task.taskTitle.length > 25 ? (					
						<div className="title"><h5>{task.taskTitle.substring(0,24)+'...'}</h5></div>
					  ) : (					
					    <div className="title"><h5>{task.taskTitle}</h5></div>
					)}				
					
					{task.taskSummary.length > 260 ? (
						<div className="summary scroll"><p>{task.taskSummary}</p></div> 
					  ) : (
						<div className="summary noscroll"><p>{task.taskSummary}</p></div> 
					)}					
				  </div>


			      <div className="taskmenu" id={index}>					  
					<div className="taskmenu-items">
					  
					  <div className="stats edit" onClick={(event) => {editTask(index)}}>					  
						<Icon.PencilFill size={16} />						
					  </div>
					  
					  <div className="stats delete" onClick={() => {deleteTask(index);}}>
						<Icon.TrashFill size={16} />
					  </div>					  
						
					  {task.status === 'completed' ? (
					  
					  <div className="stats completed" onClick={() => {completedTask(index);}}>
						<Icon.CheckCircleFill size={16} />
					  </div>
					
					  ) : (	
					  
					  <div className="stats" onClick={() => {completedTask(index);}}>
						<Icon.CheckCircleFill size={16} />
					  </div>
					  
					  )}						

					  {task.favourite === 'yes' ? ( 
					  
					  <div className="stats favourite" onClick={() => {favouriteTask(index);}}>
						<Icon.HeartFill size={16} />
					  </div>
					  
					  ) : (
					  
					  <div className="stats" onClick={() => {favouriteTask(index);}}>
						<Icon.HeartFill size={16} />
					  </div>
					  
					  )}				  
				  </div>				  
				 
				</div> 
			  </div>			
			);
		   } else {
			   return (null);
		   }	
		})}  
	   </Masonry>							
								
		) : (
															
		  <Col>
			<Card className="card-stats">			
			  <Card.Body>
				<p>you do not have any favourite note , Please add Note</p>
			  </Card.Body>	
			</Card>			 
		 </Col>
	   )}
	   
	  </Row>	  
     </Container>
	</>
  );	
	
}

export default Favourite;
